import React, { useState } from "react";
import Layout from "../components/utils/layout"
import {Link} from "gatsby"
import 'react-phone-number-input/style.css'
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input'
import { handleSignUp } from "../services/auth"
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

import "../components/css/auth/signup.scss"

const SignUp = () => {
  const [phone_number, set_phone_number] = useState()
  const [selectedUserType, setSelectedUserType] = useState("");

  function gather_user_info() {
    var data = {};

    data['first_name'] = document.getElementById('first_name').value;
    data['last_name'] = document.getElementById('last_name').value;
    data['email'] = document.getElementById('email').value;
    data['phone_number'] = phone_number;
    data['password'] = document.getElementById('password').value;
    data['repassword'] = document.getElementById('repassword').value;
    data['user_type'] = selectedUserType;

    return data;
  }

  function gather_org_info() {
    var data = {};

    data['org_name'] = document.getElementById('org_name').value;
    data['address'] = document.getElementById('address').value;
    data['city'] = document.getElementById('city').value;
    data['state'] = document.getElementById('state').value;
    data['country'] = document.getElementById('country').value;
    data['zip'] = document.getElementById('zip').value;

    return data;
  }

  function validate_email(email) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }

  function validate_password(password) {
    const re = new RegExp('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})');
    return re.test(password);
  }

  function check_user_data_empty(data) {
    if(data['first_name'].length === 0 || data['last_name'].length === 0 ||
      data['email'].length === 0 || data['phone_number'] === undefined ||
      data['password'].length === 0 || data['repassword'].length === 0) {
      return false;
    }
    return true;
  }

  function check_org_data_empty(data) {
    if(data['org_name'].length === 0 || data['address'].length === 0 ||
      data['city'].length === 0 || data['state'] === undefined ||
      data['country'].length === 0 || data['zip'].length === 0) {
      return false;
    }
    return true;
  }

  function process_user_info() {
    var data = gather_user_info();
    var is_not_empty = check_user_data_empty(data);

    if(!is_not_empty) {
      document.getElementById('empty-error').style.display = 'block';
      return;
    } else {
      document.getElementById('empty-error').style.display = 'none';
    }

    if(!validate_email(data['email'])) {
      document.getElementById('email-invalid-error').style.display = 'block';
      return;
    } else {
      document.getElementById('email-invalid-error').style.display = 'none';
    }

    if(!isValidPhoneNumber(data['phone_number'])) {
      document.getElementById('phone-invalid-error').style.display = 'block';
      return;
    } else {
      document.getElementById('phone-invalid-error').style.display = 'none';
    }

    if(!validate_password(data['password'])) {
      document.getElementById('password-weak').style.display = 'block';
      return;
    } else {
      document.getElementById('password-weak').style.display = 'none';
    }

    if(data['password'] !== data['repassword']) {
      document.getElementById('password-no-match').style.display = 'block';
      return;
    } else {
      document.getElementById('password-no-match').style.display = 'none';
    }

    document.getElementById('signup-main').style.display = 'none';
    document.getElementById('signup-org').style.display = 'block';
  }

  function signup(org=true) {
    var signup_data = {
      'user': gather_user_info(),
    }

    if (org) {
      var org_data = gather_org_info();
      if(!check_org_data_empty(org_data)) {
        document.getElementById('empty-org-error').style.display = 'block';
        return;
      } else {
        document.getElementById('empty-org-error').style.display = 'none';
      }
      signup_data['org'] = org_data;
    }

    document.getElementsByClassName('loading-div')[0].style.display = "block";
    document.getElementById('signup-main').style.display = "none";
    document.getElementById('signup-org').style.display = "none";
    document.getElementById('first-link').style.display = "none";
    handleSignUp(signup_data, org);
  }

  function handleUserTypeChange(event) {
    setSelectedUserType(event.target.value);
  }

  return (
    <Layout>
      <div id="signup-div">
        <div id="signup">
          <Link to="/">
            <div id="brand-logo-hdr">
              <h1 id="brand-logo-name">InstaLeaf</h1>
            </div>
          </Link>
          <div id="signup-main">
            <h2>Sign Up</h2>
            <input id="first_name" placeholder="First Name" type="text"/>
            <input id="last_name" placeholder="Last Name" type="text"/>
            <input id="email" placeholder="Email" type="email"/>
            <PhoneInput
              id="phone_number"
              placeholder="Phone Number"
              defaultCountry="US"
              value={phone_number}
              onChange={set_phone_number} />
            <input id="password" placeholder="Password" type="password"/>
            <input id="repassword" placeholder="Confirm Password" type="password"/>
            <select value={selectedUserType} onChange={handleUserTypeChange} id="user-type">
              <option value="" selected disabled hidden>Select Service</option>
              <option value="grower-stack">Full Grower Stack</option>
              <option value="humidity-control">Humidity Control</option>
            </select>
            {
              selectedUserType === "humidity-control" ?
              <>
                <button className="btn signup-btn" id="signup-btn" onClick={() => { signup(false); }}>Sign Up</button>
              </> :
              <>
                <button className="btn signup-btn" id="next-btn" onClick={() => { process_user_info(); }}>Next</button>
              </>
            }
            <h6 className="error" id="empty-error">All fields are required and must be filled out to sign up.</h6>
            <h6 className="error" id="email-invalid-error">Invalid email!</h6>
            <h6 className="error" id="phone-invalid-error">Invalid phone number!</h6>
            <h6 className="error" id="password-no-match">Passwords do not match!</h6>
            <h6 className="error" id="password-weak">Password is too weak. Must be 8 characters, one upper case, one lower case, one digit and one special character.</h6>
          </div>
          <div id="signup-org">
            <h2>Almost done...</h2>
            <input id="org_name" placeholder="Organization Name" type="text"/>
            <input id="address" placeholder="Address" type="text"/>
            <input id="city" placeholder="City" type="text"/>
            <input id="state" placeholder="State" type="text"/>
            <input id="country" placeholder="Country" type="text"/>
            <input id="zip" placeholder="Zip" type="text"/>
            <button className="btn signup-btn" id="signup-btn" onClick={() => { signup(); }}>Sign Up</button>
            <h6 className="error" id="empty-org-error">All fields are required and must be filled out to sign up.</h6>
          </div>
          <Link to="/login" id="first-link"><h6>Already have an account? Log In.</h6></Link>
          <div className="loading-div">
            <Loader type="Oval" color="#2ecc71" height={30} width={30}/>
            <h5>Signing in. Loading profile...</h5>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default SignUp
